import { Box, BoxProps } from '@mui/material'
import Head from 'next/head'
import { forwardRef, ReactNode } from 'react'
// material

// ----------------------------------------------------------------------

type metaTag = {
  title: string
  description: string
  keywords: string
  author: string
}

interface PageProps extends BoxProps {
  children: ReactNode
  title?: string
  metaTags?: metaTag
}
// eslint-disable-next-line react/display-name
const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ metaTags, children, title = '', ...other }, ref) => (
    <Box ref={ref} {...other}>
      <Head>
        <title>{title}</title>
        {metaTags &&
          Object.entries(metaTags).map((entry) => (
            <meta property={entry[0]} content={entry[1].toString()} key={entry[0]} />
          ))}
      </Head>
      {children}
    </Box>
  )
)

export default Page
