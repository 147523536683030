import { IconButton, IconButtonProps } from '@mui/material'
import { forwardRef } from 'react'

// material
//
import { ButtonAnimate } from '../animate'

// ----------------------------------------------------------------------
// eslint-disable-next-line react/display-name
const MIconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, ...other }, ref) => (
    <ButtonAnimate>
      <IconButton ref={ref} {...other}>
        {children}
      </IconButton>
    </ButtonAnimate>
  )
)

export default MIconButton
