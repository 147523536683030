import { doc, DocumentReference, getDoc } from 'firebase/firestore'

import { fireDB } from '../database/initDB'
import { Models } from '../database/models/entity'
import { Error } from '../globalTypes/error'

import { UserProfile } from './type'

// 0 means user does not exist, 1 means User Exist , 2 means firebase error
type Status = 0 | 1 | 2
export interface GetUserProfileByOwnerAddress {
  profile?: UserProfile
  status: Status
  message: string
}
export const getUserProfile = async (
  ownerAddress: string
): Promise<GetUserProfileByOwnerAddress> => {
  const failedResponse: GetUserProfileByOwnerAddress = {
    status: 0,
    message: '',
  }
  if (ownerAddress === '' || !ownerAddress) {
    failedResponse.message = ' ownerAddress must not be empty'
    return failedResponse
  }

  // 0 means user does not exist, 1 means User Exist , 2 means firebase error
  const docRef: DocumentReference = doc(fireDB, Models.USER_PROFILE, ownerAddress)
  try {
    const docSnap = await getDoc(docRef)
    if (!docSnap.exists()) {
      failedResponse.message = 'user does not exist'
      failedResponse.status = 0
      return failedResponse
    }
    failedResponse.status = 1
    failedResponse.profile = docSnap.data() as UserProfile
    return failedResponse
  } catch (error) {
    failedResponse.message = error.toString()
    failedResponse.status = 2
    return failedResponse
  }
}
