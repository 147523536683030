// ----------------------------------------------------------------------

import { getHost, getShopName } from '../../utils/checkShop'

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

export function pathFromSubDomain(subLink: string) {
  return `http://${getHost()}/${subLink}`
}
export function getDomainPath(subLink: string) {
  return `http://${getShopName()?.shopName}.${getHost()}/${subLink}`
}
const ROOT = '/'
const NONE = '#'

// ----------------------------------------------------------------------
export const PATH_DASHBOARD = (ROOT_DASHBOARD: string) => {
  ROOT_DASHBOARD = '/shop/' + ROOT_DASHBOARD
  return {
    root: ROOT_DASHBOARD,
    none: NONE,
    discover: path(ROOT, 'discover'),
    respect: path(ROOT, 'respect'),
    blog: {
      blogList: path(ROOT, 'blog'),
      blogPost: path(ROOT, 'blog/:slug'),
    },
    general: {
      dashboard: path(ROOT_DASHBOARD, '/dashboard'),
    },
    dashboard: path(ROOT_DASHBOARD, '/dashboard'),
    shop: {
      list: path(ROOT, 'shop'),
      create: path(ROOT, 'shop/create'),
      editById: path(ROOT, `shop/:id/edit`),
    },
    nft: {
      list: path(ROOT_DASHBOARD, '/nft'),
      nftDetails: path(ROOT_DASHBOARD, '/nft/:id'),
      create: path(ROOT_DASHBOARD, '/nft/create'),
      editById: path(ROOT_DASHBOARD, `/nft/:id/edit`),
    },
    listedOrder: {
      list: path(ROOT_DASHBOARD, '/listed-order'),
      create: path(ROOT_DASHBOARD, '/listed-order/create'),
      orderDetails: path(ROOT_DASHBOARD, '/listed-order/:id'),
    },
    collection: {
      list: path(ROOT_DASHBOARD, '/collection'),
      create: path(ROOT_DASHBOARD, '/collection/create'),
      details: path(ROOT_DASHBOARD, '/collection/:id'),
    },
    onlineStore: {
      root: path(ROOT_DASHBOARD, '/store-setting'),
      themes: path(ROOT_DASHBOARD, '/store-setting/themes'),
      domains: path(ROOT_DASHBOARD, '/store-setting/domains'),
      setting: path(ROOT_DASHBOARD, '/store-setting/setting'),
      pages: path(ROOT_DASHBOARD, '/store-setting/pages'),
    },
    promote: {
      root: path(ROOT_DASHBOARD, '/promote'),
    },
    storeProfile: path(ROOT_DASHBOARD, '/store-profile'),
    categories: path(ROOT_DASHBOARD, '/categories'),
    myAccount: {
      myAccount: path(ROOT, 'my-account'),
    },
    profile: path(ROOT, 'profile/:id'),
    nftGatedContent: {
      root: path(ROOT_DASHBOARD, '/nftgatedcontent'),
      create: path(ROOT_DASHBOARD, '/nftgatedcontent/create'),
      edit: path(ROOT_DASHBOARD, '/nftgatedcontent/edit'),
    },
  }
}
