export enum Models {
  SHOP = 'Shop',
  USER = 'User',
  CATEGORY = 'Category',
  COLLECTION = 'Collection',
  NFT_DETAILS = 'MetaData',
  SUB_COLLECTION_CUSTOM_NAVIGATION = 'CustomNavigation',
  ShopDefiDetails = 'ShopDefiDetails',
  USER_PROFILE = 'UserProfile',
  SUB_COLLECTION_SHOP_PAGES = 'ShopPages',
  SUBSCRIPTION_PLANS = 'SubscriptionPlans',

  // TODO:  NFT_DETAILS = 'NFTDetails',
}
