import axios from 'axios'
import BigNumber from 'bignumber.js'

export type getUSDReturnType = {
  symbol: string
  currentPrice: string
  amount: number
}
export const getUSD = async (symbol: string, price: BigNumber): Promise<getUSDReturnType> => {
  try {
    const res = await axios.get(
      `https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=USD`
    )
    if (res?.data?.USD) {
      return {
        amount: Number((price?.toNumber() * res?.data?.USD).toPrecision(5) || 0),
        currentPrice: res?.data?.USD,
        symbol,
      }
    }
  } catch (e) {
    return null
  }
}

export const getUsdOfERC20 = async (
  symbol: string,
  price: BigNumber,
  decimals: number
): Promise<getUSDReturnType> => {
  try {
    const res = await axios.get(
      `https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=USD`
    )
    if (res?.data?.USD) {
      return {
        amount: price
          ?.div(Math.pow(10, decimals))
          .multipliedBy(res?.data?.USD)
          .precision(3)
          .toNumber(),
        currentPrice: res?.data?.USD,
        symbol,
      }
    }
  } catch (e) {
    return null
  }
}
