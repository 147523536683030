import admin from 'firebase-admin'

import { env } from '../configs'

export const GQLHTTP = 'https://countries.trevorblades.com/'
export const FBAccount: admin.ServiceAccount = {
  projectId: env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  privateKey: process.env.FIREBASE_PRIVATE_KEY
    ? process.env.FIREBASE_PRIVATE_KEY.replace(/\\n/g, '\n')
    : '',
  clientEmail: process.env.FIREBASE_CLIENT_EMAIL,
}

export const RARIBLE_COLLECTION_CONTRACT_ADDRESS =
  env.NEXT_PUBLIC_RARIBLE_COLLECTION_CONTRACT_ADDRESS
export const METADATA_URI_PREFIX = env.METADATA_URI_PREFIX

export const chainNames = {
  1: 'Mainnet',
  4: 'Rinkeby',
  3: 'Ropsten',
  80001: 'Mumbai',
  5: 'Goerli',
}

export const chainCurrency = {
  1: 'ETH',
  4: 'ETH',
  3: 'ETH',
  80001: 'MATIC',
  5: 'ETH',
}

export const etherScansLinks = {
  1: 'https://etherscan.io/',
  4: 'https://rinkeby.etherscan.io/',
  3: 'https://ropsten.etherscan.io/',
  5: 'https://goerli.etherscan.io/',
}
export const RaribleChainNames = {
  1: 'mainnet',
  4: 'rinkeby',
  3: 'ropsten',
  80001: 'mumbai',
  5: 'goerli',
}
export const RPCURLs = {
  4: 'https://ethereum-rinkeby.deqodelabs.com',
  1: 'https://ethereum-mainnet.deqodelabs.com',
  3: 'https://ethereum-ropsten.deqodelabs.com',
  80001: 'https://rpc-mumbai.maticvigil.com',
  5: 'https://goerli.infura.io/v3/12f1faa01f464df98a3060bd37196a72',
}
export const JOYRIDE_STYLES = {
  options: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    overlayColor: 'rgba(41, 0, 79, 0.32)',
    primaryColor: '#7635dc',
    textColor: '#333',
    width: 500,
    zIndex: 99999,
  },
  buttonSkip: {
    color: 'text.secondary',
    fontSize: '12px',
  },
  tooltip: { borderRadius: '8px' },
  spotlight: { borderRadius: '8px' },
  buttonNext: { borderRadius: '25px' },
  buttonClose: { display: 'none' },
}

export const joyrideSteps = {
  homepage: [
    {
      target: '.Discover',
      content: `Discover the latest fashion in NFTs`,
    },
    {
      target: '.My',
      content: 'Explore the NFTs created/owned by you.',
    },
  ],
  dashboard: [
    {
      target: '.welcomeDash',
      content: 'Welcome to Dashboard',
    },
    {
      target: '.Dashboard',
      content: 'Explore Dashboard from sidebar.',
    },
    {
      target: '.Nft',
      content: 'Explore Nft from sidebar.',
    },
    {
      target: '.Collection',
      content: 'Explore Collection from sidebar.',
    },
    {
      target: '.Store',
      content: 'Explore Marketplace from sidebar.',
    },
    {
      target: '.Setting',
      content: 'Explore Setting from sidebar.',
    },
    {
      target: '.Promote',
      content: 'Explore Promote from sidebar.',
    },
    {
      target: '.Website',
      content: 'Explore Website from sidebar.',
    },
    {
      target: '.createNFT',
      content: 'Create your first NFT',
    },
    {
      target: '.createStore',
      content: 'Create new Marketplace',
    },
  ],
}

export enum Blockchain {
  ETHEREUM = 'ETHEREUM',
  POLYGON = 'POLYGON',
  FLOW = 'FLOW',
  TEZOS = 'TEZOS',
  SOLANA = 'SOLANA',
  IMMUTABLEX = 'IMMUTABLEX',
}
