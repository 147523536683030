import { Fab, FabProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { forwardRef } from 'react'

// material
//
import { ButtonAnimate } from '../animate'

// ----------------------------------------------------------------------

interface MFabProps extends Omit<FabProps, 'color'> {
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'
}
// eslint-disable-next-line react/display-name
const MFab = forwardRef<HTMLButtonElement, MFabProps>(
  ({ color = 'primary', children, sx, ...other }, ref) => {
    const theme = useTheme()

    if (
      color === 'default' ||
      color === 'inherit' ||
      color === 'primary' ||
      color === 'secondary'
    ) {
      return (
        <ButtonAnimate>
          <Fab ref={ref} color={color} sx={sx} {...other}>
            {children}
          </Fab>
        </ButtonAnimate>
      )
    }

    return (
      <ButtonAnimate>
        <Fab
          ref={ref}
          sx={{
            boxShadow: theme.customShadows[color],
            color: theme.palette[color].contrastText,
            bgcolor: theme.palette[color].main,
            '&:hover': {
              bgcolor: theme.palette[color].dark,
            },
            ...sx,
          }}
          {...other}
        >
          {children}
        </Fab>
      </ButtonAnimate>
    )
  }
)

export default MFab
