// material
import pinFill from '@iconify/icons-eva/external-link-outline'
import { Icon } from '@iconify/react'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import { Box, Tooltip, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { env } from '../../configs'
import { etherScansLinks } from '../../utils/constants'
import { truncateAddress } from '../utils/addressTruncate'
// ----------------------------------------------------------------------

type CopyClipboardProps = {
  value: any
  isAddress: boolean
}

export default function CopyClipboard({ value, isAddress }: CopyClipboardProps) {
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState({
    value,
    copied: false,
  })
  const onCopy = () => {
    setState({ ...state, copied: true })
    if (state.value) {
      enqueueSnackbar('Copied', { variant: 'success' })
    }
  }
  useEffect(() => {
    setState({
      value,
      copied: false,
    })
  }, [value])

  return (
    <>
      <Typography
        variant={'subtitle2'}
        sx={{
          display: 'inline',
          mr: 1,
          color: 'text.secondary',
        }}
      >
        {truncateAddress(state.value)}
      </Typography>
      <CopyToClipboard text={state.value} onCopy={onCopy}>
        <Tooltip title="Click to Copy">
          <Icon
            icon="fluent:copy-16-regular"
            width="17"
            height="17"
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      </CopyToClipboard>
      {isAddress && (
        <Tooltip title="View on Etherscan">
          <a
            href={`${etherScansLinks[parseInt(env.NEXT_PUBLIC_CHAIN_ID || '0x5')]}/address/${
              state.value
            }`}
            target={'_blank'}
            rel="noreferrer"
          >
            <Box
              component={Icon}
              icon={pinFill}
              sx={{
                width: 16,
                height: 16,
                ml: 0.5,
                flexShrink: 0,
                color: 'text.secondary',
              }}
            />
          </a>
        </Tooltip>
      )}
    </>
  )
}
