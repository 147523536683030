/* eslint-disable no-console */

import axios from 'axios'

export const getShopName = () => {
  let host = ''
  let shopName = ''
  let onstore = false

  if (window?.location) host = window?.location?.host
  const names = host?.split('.')
  if (names?.length === 3) {
    shopName = host?.split('.')[0]
    onstore = true
  }
  if (names?.length === 2 && names[1].startsWith('localhost')) {
    shopName = host?.split('.')[0]
    onstore = true
  }
  return { shopName, onstore }
}

export const getHost = () => {
  const res = getShopName()
  if (res.onstore) {
    const host = window?.location?.host
    return host.split(`${res.shopName}.`)[1]
  } else return window?.location?.host
}
