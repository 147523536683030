import { Button, Container, Fade, Grid, Stack, Tooltip, Typography } from '@mui/material'
// material
import { styled, useTheme } from '@mui/material/styles'
import { withStyles } from '@mui/styles'
import { motion } from 'framer-motion'
import Image from 'next/image'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback, useContext } from 'react'
import { position } from 'unist-util-position'

import { IsUserSubscribedContext } from '../../../contexts/IsUserSubscribedProvider'
import WalletContext from '../../../contexts/WalletContext'
import useSettings from '../../../hooks/useSettings'
import { PATH_DASHBOARD } from '../../../routes/paths'
//
import { varFadeInRight, varFadeInUp, varWrapEnter } from '../../animate'

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  // backgroundImage: `url('/static/bgCustomHeader.svg')`,
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover',
  // backgroundPositionX: '100%',
  // position: 'absolute',
  // zIndex: -1,
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    // height: '100vh',
    display: 'flex',
    // position: 'fixed',
    alignItems: 'center',
  },
}))

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
  // zIndex: 10,
  // maxWidth: 415,
  // minHeight: 800,
  margin: 'auto',
  textAlign: 'center',
  justifyContent: 'center',
  position: 'relative',
  paddingTop: 0,
  // paddingBottom: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    margin: 'unset',
    textAlign: 'left',
  },
  [theme.breakpoints.up('xs')]: {
    minHeight: 600,
  },

  [theme.breakpoints.up('sm')]: {
    minHeight: 700,
  },
}))

export const HeroOverlayStyle = styled(motion.img)({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
  position: 'absolute',
  zIndex: -1,
  right: 0,
})

const HeroImgStyle = styled(motion.img)(({ theme }) => ({
  // top: 0,
  // right: 0,
  // bottom: 0,
  zIndex: 8,
  width: '100%',
  // margin: 'auto',
  float: 'right',
  // position: 'absolute',
  [theme.breakpoints.up('lg')]: {
    // right: '17%',
    maxWidth: '600px',
    // height: 'auto',
  },
  '@media (max-width: 640px)': {
    position: 'relative',
    // top: '65px',
  },
}))

const MagicMouseComponent = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '26px',
  height: '40px',
  border: '1px solid',
  borderRadius: '13px',
  background: 'transparent',
  bottom: '0',
  margin: ' 0 auto',
  left: '0',
  right: '0',
  webkitTransition: ' all 0.3s',
  mozTransition: ' all 0.3s',
  transition: ' all 0.3s',
  zIndex: 0,
  cursor: 'pointer',
  color: theme?.palette?.mode === 'light' ? '#000' : '#fff',
  borderColor: theme?.palette?.mode === 'light' ? '#000' : '#fff',
  transform: 'translate(0px, -55px)',
}))

const MagicMouseSpan = styled('span')(({ theme }) => ({
  position: 'absolute',
  display: 'block',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  marginLeft: 'auto',
  marginRight: 'auto',
  top: '6px',
  left: '0',
  right: '0',
  webkitAnimation: 'magic-mouse 1.7s infinite ease',
  mozAnimation: 'magic-mouse 1.7s infinite ease',
  animation: 'magic-mouse 1.7s infinite ease',
  backgroundColor: theme?.palette?.mode === 'light' ? '#000' : '#fff',
}))

const AnimationItem = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'table',
  margin: '0 auto',
  zIndex: 0,
  '&:hover div span, &:hover img': {
    animation: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const MagicMouse = withStyles({
  '@global @keyframes magic-mouse': {
    from: {
      top: '6px',
      opacity: 1,
    },
    to: {
      top: '20px',
      opacity: 0,
    },
  },
})(() => {
  return (
    <MagicMouseComponent>
      <MagicMouseSpan />
    </MagicMouseComponent>
  )
})

const ArrowDownComponent = styled('img')(({ theme }) => ({
  position: 'relative',
  background: 'transparent',
  bottom: '-57px',
  margin: ' 0 auto',
  left: '0',
  right: '0',
  webkitTransition: ' all 0.3s',
  mozTransition: ' all 0.3s',
  transition: ' all 0.3s',
  zIndex: 0,
  cursor: 'pointer',
  webkitAnimation: 'arrow-down 1.7s infinite ease',
  mozAnimation: 'arrow-down 1.7s infinite ease',
  animation: 'arrow-down 1.7s infinite ease',
  filter: theme?.palette?.mode === 'light' ? '' : 'grayscale(10) invert(10)',
  webkitFilter: theme?.palette?.mode === 'light' ? '' : 'grayscale(1) invert(1)',
  transform: ' translate(0, -112px)',
}))

const ArrowDown = withStyles({
  '@global @keyframes arrow-down': {
    from: {
      top: '56px',
      opacity: 1,
    },
    to: {
      top: '70px',
      opacity: 0,
    },
  },
})(() => {
  return (
    <ArrowDownComponent
      src="/static/home/down_double_arrow.svg"
      alt="arrow_down"
      onClick={() =>
        window.scrollTo({
          top: 850,
          behavior: 'smooth',
        })
      }
    />
  )
})

const themeModeType = {
  DARK: 'dark',
  LIGHT: 'light',
}

// ----------------------------------------------------------------------

export default function LandingHero() {
  const theme = useTheme()
  const isLight = theme.palette.mode === 'light'
  const wallet = useContext(WalletContext)
  const { themeMode } = useSettings()
  const { isUserSubscribedStatus } = useContext(IsUserSubscribedContext)
  const { push } = useRouter()
  const connectWallet = useCallback(async () => {
    if (!wallet.address) {
      const walletSelected = await wallet.onboard?.walletSelect()
      if (!walletSelected) return false
      await wallet.onboard.walletCheck()
    }
  }, [wallet])

  const handleRedirectIsSubscribed = async (): Promise<void> => {
    if (isUserSubscribedStatus) push(PATH_DASHBOARD('').shop.list)
  }

  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        {themeMode === themeModeType.LIGHT && (
          <HeroOverlayStyle
            alt="overlay"
            src={themeMode === themeModeType.DARK ? '' : '/static/Path.png'}
          />
        )}

        {/*<SvgIconStyle src={'/static/bgCustomHeader.svg'} />*/}
        {/*<img src="/static/bgCustomHeader.svg" style={{position:ab}} alt="bgCustomHeader" />*/}
        <Container maxWidth="lg">
          <Grid container spacing={0} sx={{ alignItems: 'center' }}>
            <Grid className="AAAA" item xs={12} md={5}>
              <ContentStyle>
                <motion.div variants={varFadeInRight}>
                  {/* <Typography variant="h1" sx={{ color: 'common.white' }}> */}
                  <Typography variant="h2" sx={{ letterSpacing: '1px' }}>
                    Bring Your Fan Community To Web3
                  </Typography>
                </motion.div>
                <motion.div variants={varFadeInRight}>
                  <Typography variant="h4" sx={{ mb: -2, fontWeight: 400 }}>
                    Orava empowers you to sell anything you can imagine to your fans and followers.
                  </Typography>
                </motion.div>

                <motion.div variants={varFadeInRight}>
                  <Typography sx={{ color: isLight ? 'text.secondary' : 'common.white' }}>
                    Create unique and sellable fan experiences.
                  </Typography>
                  <Typography sx={{ color: isLight ? 'text.secondary' : 'common.white', mt: 1 }}>
                    Monetize easily.
                  </Typography>
                  <Typography sx={{ color: isLight ? 'text.secondary' : 'common.white', mt: 1 }}>
                    Orava promises you a safe, fun, and totally free way to set up your community
                    for unique fan experiences.
                  </Typography>
                </motion.div>
                <motion.div variants={varFadeInRight}>
                  <Tooltip
                    title="Take a tour"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 200 }}
                  >
                    <>
                      {!wallet.address && (
                        <>
                          <Button
                            variant="contained"
                            className="connectMetamask"
                            onClick={connectWallet}
                            size={'large'}
                            sx={{
                              borderRadius: '25px',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Connect Wallet
                          </Button>
                        </>
                      )}
                      {wallet.address && (
                        <>
                          {/* <NextLink href={PATH_DASHBOARD('').shop.list}> */}
                          <Button
                            variant="outlined"
                            className="manageStore"
                            size={'large'}
                            sx={{
                              borderRadius: '25px',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={handleRedirectIsSubscribed}
                          >
                            Get Started
                          </Button>
                          {/* </NextLink> */}
                          {/* <AccountPopover /> */}
                        </>
                      )}
                    </>
                  </Tooltip>
                </motion.div>
              </ContentStyle>
            </Grid>
            <Grid item xs={12} md={7}>
              <HeroImgStyle
                alt="hero"
                src={
                  themeMode === themeModeType.DARK
                    ? '/static/home/NFT_pack_squirrell.png'
                    : '/static/home/Squirrell_BG1.png'
                }
                variants={varFadeInUp}
              />
            </Grid>
          </Grid>

          {/*Can be Animated with single class*/}
          {/* <AnimationItem
            onClick={() =>
              window.scrollTo({
                top: 850,
                behavior: 'smooth',
              })
            }
          >
            <MagicMouse />
            <ArrowDown />
          </AnimationItem> */}
        </Container>
      </RootStyle>
    </>
  )
}
